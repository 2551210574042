<template>

  <div v-if="contentData"><b-row><b-col md="12">

    <b-card>
      <b-card-header>
        <!-- title and subtitle -->
        <div>
          <b-card-title
            v-if="contentData.content_types===0"
            class="mb-1"
          >
            <span
              v-for="contentType in $staticParams.blogContentTypes"
              :key="contentType.id"
            >
              {{ contentType.page_title }} -
            </span>
          </b-card-title>
          <b-card-title
            v-else
            class="mb-1"
          >
            {{ $staticParams.getBlogContentType(contentData.content_types).page_title }}
          </b-card-title>
          <b-card-sub-title />
        </div>
        <!--/ title and subtitle -->

        <!-- badge -->
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
          <h5 class="font-weight-bolder mb-0 mr-1" />

        </div>
        <!--/ badge -->
      </b-card-header>
      <!-- eslint-disable vue/no-v-html -->
      <b-card-body>
        <b-list-group>
          <b-list-group-item
            v-for="content in contentData.contents.data"
            :key="content.id"
            :to="{ name: 'content-show', params: { id: content.id } }"
            class="flex-column align-items-start"
          >

            <div class="d-flex w-100 justify-content-between">
              <h5
                class="mb-1"
              >
                {{ content.title }}
              </h5>
              <small class="text-secondary" />
            </div>
            <b-card-text class="mb-1">
              {{ $staticParams.getBlogContentType(content.content_type).label }}
            </b-card-text>

          </b-list-group-item>

        </b-list-group>
        <div>
          <b-pagination
            :value="1"
            :total-rows="totalRows"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @change="handleChangePage"
            @input="(value)=>props.pageChanged({currentPage:value})"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>

  </b-col>
  </b-row>
  </div>
</template>

<script>
import {
  BCard, BPagination, BListGroup, BListGroupItem,
  BCardHeader, BCardText,
  BRow,
  BCardBody,
  BCol,
  BCardTitle,
  BCardSubTitle,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { onUnmounted } from '@vue/composition-api'
import blogContentStoreModule from '../blogContentStoreModule'

export default {
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCardBody,
    BCol,
    BPagination,
    BCardTitle,
    BCardText,
    BListGroup,
    BListGroupItem,
    BCardSubTitle,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      totalRows: 0,
      pageLength: 30,
      page: 1,
      contentData: null,
    }
  },
  created() {
    this.fetchContents()
  },
  methods: {
    dateFormat(date) { return this.$moment(date).fromNow() },
    handleChangePage(page) {
      this.page = page
      this.fetchContents()
    },
    fetchContents() {
      let contentType = 0
      if (this.$router.currentRoute.params.content_type !== undefined) {
        const contentTypeObj = this.$staticParams.getBlogContentType(this.$router.currentRoute.params.content_type, 'key')

        if (contentTypeObj != null) {
          contentType = contentTypeObj.value
        }
      }

      store.dispatch('app-blog-content/fetchBlogContentsForShowing', { page: this.page, content_type: contentType })
        .then(response => {
          this.contentData = response.data.data
          const { meta } = response.data
          this.totalRows = meta.total
        })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-blog-content'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, blogContentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
  },
}
</script>
